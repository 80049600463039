<template>
    <b-form-group
        :id="'fieldset-horizontal-label-'+ value.dataNamePrefix"
        label-cols-sm="4"
        label-cols-lg="3"
        :label-size="size"
        :label="inputLabel"
        :invalid-feedback="invalidFeedback"
        :state="validationResult.all"
    >
        <b-row no-gutters>
            <b-col cols="12">
                <!-- Line Two AKA Care Of -->
                <b-form-group
                    id="fieldset-adrs-careof"
                    description="Care Of"
                    label-for="adrs-careof"
                >
                    <b-form-input id="adrs-careof" :size="size" v-model="inputValue.lineTwo" @input="touch($event,'lineTwo')" trim></b-form-input>
                </b-form-group>
            </b-col>
            <!-- <b-col cols="12">
                <b-form-group
                    id="fieldset-adrs-input"
                    description="Address Input"
                    label-for="adrs-input"
                >
                    <b-form-textarea 
                        id="adrs-input"
                        v-model="inputValue.concat" 
                        @input="requestParse($event)"
                        placeholder="Begin Typing An Address"
                        rows="2"
                        :size="size"
                        max-rows="4"
                    >
                    </b-form-textarea>
                </b-form-group>
            </b-col> -->
            <b-col cols="12" sm="2" xl="1">
                <!-- Number -->
                <b-form-group
                    id="fieldset-adrs-number"
                    description="Number"
                    label-for="adrs-number"
                >
                    <b-form-input id="adrs-number" :size="size" v-model="inputValue.number" @input="touch($event,'number')" type="number" trim></b-form-input>
                </b-form-group>
            </b-col>
            <b-col cols="12" sm="3" xl="1">
                <!-- Predirectional -->
                <b-form-group
                    id="fieldset-adrs-predirection"
                    description="Predirection"
                    label-for="adrs-predirection"
                >
                    <b-form-input id="adrs-predirection" :size="size" v-model="inputValue.predirectional" @input="touch($event,'predirectional')" trim></b-form-input>
                </b-form-group>
            </b-col>
            <b-col cols="12" sm="5" xl="5">
                <!-- Street Name -->
                <b-form-group
                    id="fieldset-adrs-name"
                    description="Street Name"
                    label-for="adrs-name"
                >
                    <b-form-input id="adrs-name" :size="size" v-model="inputValue.name" @input="touch($event,'name')" trim></b-form-input>
                </b-form-group>
            </b-col>
            <b-col cols="12" sm="2" xl="1">
                <!-- Suffix -->
                <b-form-group
                    id="fieldset-adrs-suffix"
                    description="Type"
                    label-for="adrs-suffix"
                >
                    <b-form-input id="adrs-suffix" :size="size" v-model="inputValue.suffix" @input="touch($event,'suffix')" trim></b-form-input>
                </b-form-group>
            </b-col>
            <b-col cols="12" sm="6" xl="2">
                <!-- Postdirectional -->
                <b-form-group
                    id="fieldset-adrs-postdirectional"
                    description="Postdirection"
                    label-for="adrs-postdirectional"
                >
                    <b-form-input id="adrs-postdirectional" :size="size" v-model="inputValue.postdirectional" @input="touch($event,'postdirectional')" trim></b-form-input>
                </b-form-group>
            </b-col>
            <!-- <b-col cols="3" sm="2" xl="1">               
                <b-form-group
                    id="fieldset-adrs-building"
                    description="Building"
                    label-for="adrs-building"
                >
                    <b-form-input id="adrs-building" :size="size" v-model="inputValue.building" @input="touch($event,'building')" trim></b-form-input>
                </b-form-group>
            </b-col> -->
            <b-col cols="6" sm="6" xl="2">
                <!-- Unit -->
                <b-form-group
                    id="fieldset-adrs-unit"
                    description="Unit"
                    label-for="adrs-unit"
                >
                    <b-form-input id="adrs-unit" :size="size" v-model="inputValue.unit" @input="touch($event,'unit')" trim></b-form-input>
                </b-form-group>
            </b-col>
            <!-- <b-col cols="3" sm="4" xl="1">               
                <b-form-group
                    id="fieldset-adrs-extra"
                    description="Extra"
                    label-for="adrs-extra"
                >
                    <b-form-input id="adrs-extra" :size="size" v-model="inputValue.extra" @input="touch($event,'extra')" trim></b-form-input>
                </b-form-group>
            </b-col> -->
            <b-col cols="8" sm="7" xl="9">
                <!-- City -->
                <b-form-group
                    id="fieldset-adrs-city"
                    description="City"
                    label-for="adrs-city"
                >
                    <b-form-input id="adrs-city" :size="size" v-model="inputValue.city" @input="touch($event,'city')" trim></b-form-input>
                </b-form-group>
            </b-col>
            <b-col cols="4" sm="2" xl="1">
                <!-- State -->
                <b-form-group
                    id="fieldset-adrs-state"
                    description="State"
                    label-for="adrs-state"
                >
                    <b-form-input id="adrs-state" :size="size" v-model="inputValue.state" @input="touch($event,'state')" trim></b-form-input>
                </b-form-group>
            </b-col>
            <b-col cols="5" sm="3" xl="2">
                <!-- Zip -->
                <b-form-group
                    id="fieldset-adrs-zip"
                    description="Zip"
                    label-for="adrs-zip"
                >
                    <b-form-input id="adrs-zip" :size="size" v-model="inputValue.zip" @input="touch($event,'zip')" type="number" trim></b-form-input>
                </b-form-group>
            </b-col>
            <!-- <b-col cols="4" sm="2" xl="1">
               
                <b-form-group
                    id="fieldset-adrs-zipPlusFour"
                    description="+4"
                    label-for="adrs-zipPlusFour"
                >
                    <b-form-input id="adrs-zipPlusFour" :size="size" v-model="inputValue.zipPlusFour" @input="touch($event,'zipPlusFour')" type="number" trim></b-form-input>
                </b-form-group>
            </b-col>
            <b-col cols="3" sm="1" xl="1">
               
                <b-form-group
                    id="fieldset-adrs-zipPlusTwo"
                    description="+2"
                    label-for="adrs-zipPlusTwo"
                >
                    <b-form-input id="adrs-zipPlusTwo" :size="size" v-model="inputValue.zipPlusTwo" @input="touch($event,'zipPlusTwo')" type="number" trim></b-form-input>
                </b-form-group>
            </b-col> -->
            <!-- <b-col cols="12">
                <b-form-group
                    id="fieldset-adrs-result"
                    description="Address Result"
                    label-for="adrs-result"
                >
                    <b-form-textarea 
                        id="adrs-input-result"
                        disabled
                        v-model="concatenatedAddressWithCareOf"
                        :size="size"
                        rows="2"
                        max-rows="4"
                    >
                    </b-form-textarea>
                </b-form-group>
            </b-col> -->
        </b-row>
    </b-form-group>
</template>

<script>
const cloneDeep = require('lodash.clonedeep');
const axios = require('axios');
const _ = require('underscore');
const instance = axios.create({ timeout: 10000, headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true });
// import MicroArrayAddButton from '@/components/microcomponents/ArrayItemAddButton.vue'
// import MicroArrayRemoveButton from '@/components/microcomponents/ArrayItemRemoveButton.vue'

export default {
    name: 'formaddressinputwithparsing',
    components:{
        // 'btn-add': MicroArrayAddButton,
        // 'btn-remove': MicroArrayRemoveButton
    },
    props:{
        // dataName: String,
        inputLabel: String,
        // apiEndpoint: String,
        parentFormID: String,
        value: {
            type: [Object],
            default: (val) => {
                return {
                    dataNamePrefix: null,
                    number: null,
                    predirectional: null,
                    name: null,
                    suffix: null,
                    postdirectional: null,
                    unit: null,
                    building: null,
                    extra: null,
                    city: null,
                    state: null,
                    zip: null,
                    zipPlusFour: null,
                    zipPlusTwo: null,
                    lineTwo: null
                }
            }
        },
        isSite: {
            type: Boolean,
            default: () => {return false} 
        },
        size: {
            type: String,
            validator: (val) =>{
                // The value must match one of these strings
                return ['sm', 'md', 'lg'].indexOf(val) != -1
            },
            default: () => {return "md"}
        },
        // required: {
        //     type: Boolean,
        //     default: () => {return false} 
        // },
        // readOnly: {
        //     type: Boolean,
        //     default: () => {return false} 
        // },
        index: {
            type: Number,
            default: () => {return -1;}
        },
        // appendComponent: {
        //     type: String,
        //     default: () => {return null} 
        // },
        // prependComponent: {
        //     type: String,
        //     default: () => {return null} 
        // },
        // displayCount: {
        //     type: Number,
        //     default: () => {return 1;} 
        // }
    },
    data(){
        return{
            //parentFormID: 'b78f4b32-1964-4ace-985e-850748f5d961',
            keyList: ["number","predirectional","name","suffix","postdirectional","unit","building","extra","city","state","zip","zipPlusFour","zipPlusTwo", "lineTwo"],
            inputValue: {
                dataNamePrefix: null,
                concat: null,
                number: null,
                predirectional: null,
                name: null,
                suffix: null,
                postdirectional: null,
                unit: null,
                building: null,
                extra: null,
                city: null,
                state: null,
                zip: null,
                zipPlusFour: null,
                zipPlusTwo: null,
                lineTwo: null
            },
            originalValue: {},
            useParser: "js",
            showExtended: false,
            options: [],
            isDirty: false,
            count: 1
        }
    },
    methods: {
        touch(event, name) {
            // this.isDirty = true;
            var reportType = this.getReportType(name);
            var reportDataName = this.value.dataNamePrefix + this.getDataPostfix(name);
            this.$store.commit('updateFormValue', {parentFormID: this.parentFormID, type: reportType, dataName: reportDataName, index: this.index, value: this.inputValue[name], valid: true, isDirty: true} );
            // this.$emit('onTouch', this.dataName);
        },
        prependInput(){
            // this.$store.commit('formPrepend', {parentFormID: this.parentFormID, dataName: this.dataName, valid: this.validationResult.all});
        },
        getReportType(name){
            if(name == "number" || name == "zip" || name == "zipPlusFour" || name == "zipPlusTwo"){
                return "formintegerinput";
            }else{
                return "formtextregexinput";
            }
        },
        getDataPostfix(name){
            switch (name) {
                case "number":
                    return "_number";
                    break;
                case "predirectional":
                    return "_predirection";
                    break;
                case "name":
                    return "_street_name";
                    break;
                case "suffix":
                    return "_street_suffix";
                    break;
                case "postdirectional":
                    if(this.isSite){
                        return "_postdirection";
                    }else{
                        return "_postdirectional";
                    }
                    break;
                case "unit":
                    return "_unit";
                    break;
                case "building":
                    return "_building";
                    break;
                case "extra":
                    return "_extra";
                    break;
                case "city":
                    return "_city";
                    break;
                case "state":
                    return "_state";
                    break;
                case "zip":
                    return "_zip_code";
                    break;
                case "zipPlusFour":
                    return "_zip_code_plus_four";
                    break;
                case "zipPlusTwo":
                    return "_zip_code_plus_two";
                    break;
                case "lineTwo":
                    return "_line_two";
                    break;
                default:
                    return null;
                    break;
            }
        },
        requestParse: _.debounce(function(){
            const newLineRegex = /(\n|\r|\r\n)/g;
            var adrsCleaned = this.inputValue.concat.replace(newLineRegex, " ");
            var reqObj = {
                address: adrsCleaned
            };
            console.log(`Make Request To API Parser: ${adrsCleaned}`);
            instance.post(process.env.VUE_APP_API_BASE_URL + `/atils/parse_address.json?parserType=${this.useParser}`, reqObj)
            .then((response)=>{
                // TODO: some logic to prevent overwrite of already manually filled fields
                console.log(response.data.result);
                var parsed = response.data.result;
                if(this.inputValue.number != parsed.number){
                    this.inputValue.number = parsed.number;
                    this.touch(null, "number");
                }
                if(this.inputValue.predirectional !=  parsed.predirectional){
                    this.inputValue.predirectional =  parsed.predirectional;
                    this.touch(null, "predirectional");
                }
                if(this.inputValue.name !=  parsed.name){
                    this.inputValue.name =  parsed.name;
                    this.touch(null, "name");
                }
                if(this.inputValue.suffix !=  parsed.suffix){
                    this.inputValue.suffix =  parsed.suffix;
                    this.touch(null, "suffix");
                }
                if(this.inputValue.postdirectional !=  parsed.postdirectional){
                    this.inputValue.postdirectional =  parsed.postdirectional;
                     if(this.isSite){
                        this.touch(null, "postdirection");
                    }else{
                        this.touch(null, "postdirectional");
                    }
                }
                if(this.inputValue.unit !=  parsed.unit){
                    this.inputValue.unit =  parsed.unit;
                    this.touch(null, "unit");
                }
                if(this.inputValue.building !=  parsed.building){
                    this.inputValue.building =  parsed.building;
                    this.touch(null, "name");
                }
                if(this.inputValue.extra !=  parsed.extra){
                    this.inputValue.extra =  parsed.extra;
                    this.touch(null, "name");
                }
                if(this.inputValue.city !=  parsed.city){
                    this.inputValue.city =  parsed.city;
                    this.touch(null, "city");
                }
                if(this.inputValue.state !=  parsed.state){
                    this.inputValue.state =  parsed.state;
                    this.touch(null, "state");
                }
                if(this.inputValue.zip !=  parsed.zip){
                    this.inputValue.zip =  parsed.zip;
                    this.touch(null, "zip");
                }
                if(this.inputValue.zipPlusFour !=  parsed.zipPlusFour){
                    this.inputValue.zipPlusFour =  parsed.zipPlusFour;
                    this.touch(null, "zipPlusFour");
                }
            })
            .catch((err)=>{
                console.log("Address Input - Error Durring Parse Request")
                console.log(err);
            })
        }, 500)
    },
    computed: {
        concatenatedAddressWithCareOf: function(){
            var wCareOf = "";
            if(this.inputValue.lineTwo != null){
                wCareOf += this.inputValue.lineTwo + "\n";
            }
            wCareOf += this.concatenatedAddress;
            return wCareOf;
        },
        concatenatedAddress: function(){
            // console.log("Computing Concatenated Address With InputValue:")
            // console.log(this.inputValue);
            // console.log(this.inputValue.number);
            var concat = "";
            if(this.inputValue.number != null){
                concat += this.inputValue.number;
            }
            if(this.inputValue.predirectional != null){
                if(concat.length > 0){
                    concat += " " + this.inputValue.predirectional;
                }else{
                    concat += this.inputValue.predirectional;
                }
            }
            if(this.inputValue.name != null){
                if(concat.length > 0){
                    concat += " " + this.inputValue.name;
                }else{
                    concat += this.inputValue.name;
                }
            }
            if(this.inputValue.suffix != null){
                if(concat.length > 0){
                    concat += " " + this.inputValue.suffix;
                }else{
                    concat += this.inputValue.suffix;
                }
            }
            if(this.inputValue.postdirectional != null){
                if(concat.length > 0){
                    concat += " " + this.inputValue.postdirectional;
                }else{
                    concat += this.inputValue.postdirectional;
                }
            }
            if(this.inputValue.building != null){
                if(concat.length > 0){
                    concat += ", " + this.inputValue.building;
                }else{
                    concat += this.inputValue.building;
                }
            }
            if(this.inputValue.unit != null){
                if(concat.length > 0){
                    if(this.inputValue.building == null){
                        concat += ", " + this.inputValue.unit;
                    }else{
                        concat += " " + this.inputValue.unit;
                    }
                }else{
                    concat += this.inputValue.unit;
                }
            }
            if(this.inputValue.extra != null){
                if(concat.length > 0){
                    if(this.inputValue.building == null && this.inputValue.unit == null){
                        concat += ", " + this.inputValue.extra;
                    }else{
                        concat += " " + this.inputValue.extra;
                    }
                }else{
                    concat += this.inputValue.extra;
                }
            }
            if(this.inputValue.city != null){
                if(concat.length > 0){
                    concat += "\n" + this.inputValue.city;
                }else{
                    concat += this.inputValue.city;
                }
            }
            if(this.inputValue.state != null){
                if(this.inputValue.city != null){
                    concat += ", " + this.inputValue.state;
                }else{
                    concat += "\n" + this.inputValue.state;
                }
            }
            if(this.inputValue.zip != null){
                if(this.inputValue.city != null || this.inputValue.state != null){
                    concat += " " + this.inputValue.zip;
                }else{
                    concat += "\n" + this.inputValue.zip;
                }
            }
            if(this.inputValue.zipPlusFour != null){
                if(this.inputValue.zip != null){
                    concat += "-" + this.inputValue.zipPlusFour;
                }
            }
            if(this.inputValue.zipPlusTwo != null){
                if(this.inputValue.zipPlusFour != null){
                    concat += "-" + this.inputValue.zipPlusTwo;
                }
            }
            return concat;
        },
        // a computed getter
        validationResult: function () {
            return {matchOptions: true, requirementFilled: true, all: true}
        //     var results = this.options.filter(elm => elm.value == this.inputValue);
        //     var match = true;
        //     if( (results == null || results.length == 0) && this.required)
        //         match = false;
        //     // var regexRes = ( (!this.required) ? this.validator.test(this.inputValue) || this.inputValue.length == 0 : this.validator.test(this.inputValue) );
        //     var requiredRes = ( ((this.required && this.inputValue == null) || (this.required && this.inputValue.length == 0)) ? false : true) ;

        //     return {matchOptions: match, requirementFilled: requiredRes, all: (match && requiredRes)};
        },
        invalidFeedback(){
        //     var res = this.validationResult;
        //     if(res.all)
        //         return ''
        //     else if(!res.requirementFilled)
        //         return 'Required'
        //     else if(!res.matchOptions)
        //         return 'Invalid Entry'
        //     else
        //         return 'OK'
        }
    },
    mounted(){
        // this.$store.commit('initForm',{ localFormID: this.parentFormID });
        this.keyList.forEach((key)=>{
            var reportType = this.getReportType(key);
            var reportDataName = this.value.dataNamePrefix + this.getDataPostfix(key);
            // console.log(`Registering ${reportDataName} with Value: ${this.inputValue[key]} | name: ${key}`)
            this.$store.commit('registerFormValue', {parentFormID: this.parentFormID, type: reportType, dataName: reportDataName, value: this.inputValue[key], index: this.index, valid: true, isDirty: true} );
        })
    },
    created(){
    },
    beforeDestroy(){
        //this.$store.commit('removeFormValue', {parentFormID: this.parentFormID, dataName: this.dataName} );
    },
    beforeMount (){
        // console.log("Before Mount value is")
        // console.log(this.value)
        this.originalValue = cloneDeep(this.value);
        this.inputValue = this.value;
        this.inputValue.concat = this.concatenatedAddress;
    },
}
</script>

<style scoped>
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button:hover { 
  -webkit-appearance: none; 
  margin: 0; 
}
</style>


