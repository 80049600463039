<template>
    <div v-if="$store.getters.checkUIPC({path: ['form', 'access'] })" 
        :key="formKey" 
        :class="(isChild) ? 'nested-form-left-margin' : `${(vertScroll) ? 'stage-inner-container-padded' : ' pr-3 stage-inner-container-padded'}`"
    >
        <!-- Route Header -->
        <b-row v-if="!!showTitle && formDef.meta.formParentKey == null && !isChild" class="mt-3">
            <b-col cols="12" lg="3" class="pl-3">
                <span class="form-title">{{formDef.meta.name}}</span><br>
                <span class="form-sub-title">{{formDef.meta.title}}</span><br>
                <b-link v-if="formDef.meta.formInspects == 'devices' && deviceMostRecentPhoto.photo != null && deviceMostRecentPhoto.photo != undefined" v-b-popover.hover.bottom="'View This Event (Opens In New Tab)'" target="_blank" :to="mostRecentPhotoLink()">
                    Most Recent Device Photo From {{deviceMostRecentPhoto.photoType}} on {{parseTimestampToDate(deviceMostRecentPhoto.tstamp)}}
                </b-link>
                <div v-if="formDef.meta.formInspects == 'devices'">
                    <outstanding-compliance-notice :deviceID="formDef.meta.inspect"></outstanding-compliance-notice>
                </div>
            </b-col>
            <b-col cols="12" lg="3" v-if="formDef.meta.formInspects == 'devices' && deviceMostRecentPhoto.photo != null && deviceMostRecentPhoto.photo != undefined">
                <hover-image-with-modal :imgID="deviceMostRecentPhoto.photo"></hover-image-with-modal>
            </b-col>
            <b-col cols="12" lg="6">
                
            </b-col>
        </b-row>
        <!-- Show Go To Parent -->
        <b-row v-else-if="!!showTitle && formDef.meta.formParentKey != null && !isChild">
            <b-col class="breadcrumbs-bar-static" cols="12">
                <b-breadcrumb class="vai-breadcrumb" :items="breadcrumbs"></b-breadcrumb>
            </b-col>
            <b-col cols="12">
                <br><br><br>
                <b-row>
                    <b-col cols="12" lg="3" class="pl-3">
                        <span class="form-title">{{formDef.meta.name}}</span><br>
                        <span class="form-sub-title">{{formDef.meta.title}}</span><br>
                        <b-link v-if="formDef.meta.formInspects == 'devices' && deviceMostRecentPhoto.photo != null && deviceMostRecentPhoto.photo != undefined" v-b-popover.hover.bottom="'View This Event (Opens In New Tab)'" target="_blank" :to="mostRecentPhotoLink()">
                            Most Recent Device Photo From {{deviceMostRecentPhoto.photoType}} on {{parseTimestampToDate(deviceMostRecentPhoto.tstamp)}}
                        </b-link>
                        <div v-if="formDef.meta.formInspects == 'devices'">
                            <outstanding-compliance-notice :deviceID="formDef.meta.inspect"></outstanding-compliance-notice>
                        </div>
                    </b-col>
                    <b-col cols="12" lg="3" v-if="formDef.meta.formInspects == 'devices' && deviceMostRecentPhoto.photo != null && deviceMostRecentPhoto.photo != undefined">
                        <hover-image-with-modal :imgID="deviceMostRecentPhoto.photo"></hover-image-with-modal>
                    </b-col>
                    <b-col v-else cols="0" lg="3"></b-col>
                    <b-col cols="12" lg="5">
                    </b-col>
                    <b-col cols="12" lg="1">
                        <div class="float-right">
                            <adv-opt-dd
                                :changeParentCallback="((formDef.meta.formInspects != 'customers') ? requestChangeParent : null)"
                                :formInspects="formDef.meta.formInspects"
                                :inspect="formDef.meta.inspect"
                                :formID="localFormID"
                            ></adv-opt-dd>
                        </div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row v-if="formDef.meta.formInspects == 'devices' && deviceMostRecentPhoto.photo != null && deviceMostRecentPhoto.photo != undefined && isChild && !hideFields && showPhoto">
            <b-col cols="12" lg="3" class="pl-3">
                <span class="form-title">Most Recent Photo</span><br>
                <b-link v-b-popover.hover.bottom="'View This Event (Opens In New Tab)'" target="_blank" :to="mostRecentPhotoLink()">
                    From {{deviceMostRecentPhoto.photoType}} on {{parseTimestampToDate(deviceMostRecentPhoto.tstamp)}}
                </b-link>
                <div v-if="formDef.meta.formInspects == 'devices'">
                    <outstanding-compliance-notice :deviceID="formDef.meta.inspect"></outstanding-compliance-notice>
                </div>
            </b-col>
            <b-col cols="12" lg="3" v-if="formDef.meta.formInspects == 'devices' && deviceMostRecentPhoto.photo != null && deviceMostRecentPhoto.photo != undefined">
                <hover-image-with-modal :imgID="deviceMostRecentPhoto.photo"></hover-image-with-modal>
            </b-col>
            <b-col cols="12" lg="6">
            </b-col>
        </b-row>
        <b-row>
           <b-col cols="12">
               <change-parent v-if="formDef.meta.formInspects != 'customers' && formDef.meta.inspect != null"
                    :attachedToType="formDef.meta.formInspects"
                    :attachedToID="formDef.meta.inspect"
                    :requestChangeParent="changeParentIncrement"
                ></change-parent>
                <attachment-list class="my-2" v-if="formDef.meta.inspect != null && showFlags && !hideFields"  
                    :parentID="inspect"
                ></attachment-list>
               <flag-list class="my-2" v-if="formDef.meta.inspect != null && showFlags && !hideFields"  
                    :parentID="formDef.meta.inspect"
                ></flag-list>
               <b-form v-on:submit.prevent>
                    <div class="array-spaced" v-for="(comp, index) in formDef.fields" :key="index">
                        <component v-if="!hideFields || comp.type == 'nested-child-forms' || comp.type == 'section-header'"
                            :is="comp.type"
                            :dataName="comp.dataName"
                            :inputLabel="comp.inputLabel"
                            :value="comp.value"
                            :required="comp.required"
                            :readOnly="comp.readOnly"
                            :validator="(comp.validator != null) ? new RegExp(comp.validator ,'') : undefined"
                            :multiSelect="comp.multiSelect"
                            :options="comp.options"
                            :apiEndpoint="comp.apiEndpoint"
                            :latitude="comp.latitude"
                            :size="comp.size"
                            :longitude="comp.longitude"
                            :latitudeLabel="comp.latitudeLabel"
                            :longitudeLabel="comp.longitudeLabel"
                            :componentName="comp.componentName"
                            :threeState="comp.threeState"
                            :parentFormID="localFormID"
                            :formID="comp.formID"
                            :isSite="(formDef.meta.formInspects == 'sites') ? true : false"
                            :sendForm="sendForm"
                            :runOnDiscard="runOnDiscard"
                            :backOnDiscard="backOnDiscard"
                        ></component>
                    </div>
                    <div class="mb-2" v-if="(formDef.meta.formInspects == 'devices' || formDef.meta.formInspects == 'technicians') ? false : !hideFields">
                        <b-button block v-if="(formDef.meta.formInspects == 'connections' && $store.getters.checkUIPC({path: ['dashboard', 'actions', 'createDevice'] })) ? true : false" @click="eventActionTriggered({action: 'addnewdevice', parentID: formDef.meta.inspect })">
                            <span class="align-middle float-left"><i class="material-icons drop-down">add</i></span>
                            <span class="float-left text-match-drop-down align-middle">Add Device</span>
                        </b-button>
                        <b-button block v-if="(formDef.meta.formInspects == 'sites' && $store.getters.checkUIPC({path: ['dashboard', 'actions', 'createConnection'] })) ? true : false" @click="eventActionTriggered({action: 'addnewconnection', parentID: formDef.meta.inspect })">
                            <span class="align-middle float-left"><i class="material-icons drop-down">add</i></span>
                            <span class="float-left text-match-drop-down align-middle">Add Connection</span>
                        </b-button>
                        <b-button block v-if="(formDef.meta.formInspects == 'customers' && $store.getters.checkUIPC({path: ['dashboard', 'actions', 'createSite'] })) ? true : false" @click="eventActionTriggered({action: 'addnewsite', parentID: formDef.meta.inspect })">
                            <span class="align-middle float-left"><i class="material-icons drop-down">add</i></span>
                            <span class="float-left text-match-drop-down align-middle">Add Site</span>
                        </b-button>
                    </div>
                    <submit-discard v-if="hasNestedChildren == false && !hideFields"
                        :backOnDiscard="backOnDiscard"
                        :runOnDiscard="runOnDiscard"
                        :sendForm="sendForm"
                    ></submit-discard>       
                </b-form>
            </b-col> 
        </b-row>
        <b-row v-if="!hideFields && !hideEventList && $store.getters.checkUIPC({path: ['eventList', 'access'] })">
             <b-col cols="12">
                 <b-row class="mt-2">
                        <!-- Spacer -->
                    </b-row>
                    <!-- v-if="formDef.meta.showFlags" -->
                    <b-card v-if="formDef.meta.showEventHist" header="Events" header-class="larger-header">
                        <b-card-body>
                            <event-list 
                                :parentID="formDef.meta.inspect" 
                                :showTestBtn="(formDef.meta.formInspects == 'devices') ? true : false"
                                :showReplacementBtn="(formDef.meta.formInspects == 'devices') ? true : false"
                                :showIssueToFulcrumBtn="(formDef.meta.formInspects == 'devices') ? true : false"
                                :showInstallBtn="(formDef.meta.formInspects == 'connections') ? true : false"
                                :showAddNewDeviceBtn="(formDef.meta.formInspects == 'connections') ? true : false"
                                :showAddNewConnectionBtn="(formDef.meta.formInspects == 'sites') ? true : false"
                                :showAddNewSiteBtn="(formDef.meta.formInspects == 'customers') ? true : false"
                                @eventlistbuttonpressed="eventActionTriggered"
                            ></event-list>
                        </b-card-body>
                    </b-card>
             </b-col>
        </b-row>
        <issue-to-fulcrum-modal :parentID="localFormID" :deviceID="formDef.meta.inspect"></issue-to-fulcrum-modal>
    </div>
</template>

<script>
const axios = require('axios');
const instance = axios.create({ timeout: 20000, headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true });
const uuidv4 = require('uuid/v4');
const cloneDeep = require('lodash.clonedeep');
const _ = require('underscore');
const butils = require('../libs/basicUtils.js')

// Form Components
import RegexText from '@/components/FormTextRegexInput.vue'
import TextArea from '@/components/FormTextAreaInput.vue'
import PassedSelect from '@/components/FormSelectPassedValuesInput.vue'
import APISelect from '@/components/FormSelectFetchAPIInput.vue'
import Decimal2x2Input from '@/components/FormDecimalTwoByTwoInput.vue'
import DecimalInput from '@/components/FormDecimalInput.vue'
import IntegerInput from '@/components/FormIntegerInput.vue'
import GPSInput from '@/components/FormGPSInput.vue'
import PhoneInput from '@/components/FormPhoneInput.vue'
import CertInput from '@/components/FormCertInput.vue'
import EmailInput from '@/components/FormEmailInput.vue'
import FormArrayInput from '@/components/FormArrayEngine.vue'
import RadioInput from '@/components/FormRadioInput.vue'
import BoolInput from '@/components/FormBooleanInput.vue'
import AddressInput from '@/components/FormAddressComponent.vue'
import NestedForm from '@/components/NestedChildrenForms.vue'
import EventList from '@/components/FormEventListHistory.vue'
import IssueFulcrum from '@/components/subcomponents/IssueToFulcrumModal.vue'
import FlagList from '@/components/FormFlagList.vue'
import SubmitDiscard from '@/components/FormSubmitDiscard.vue'
import SectionHeader from '@/components/FormSectionHeader.vue'
import Image from '@/components/subcomponents/Image.vue';
import FormImage from '@/components/FormImageWithLabel.vue'
import ChangeParent from '@/components/subcomponents/ChangeParent.vue'
import AdvancedActionsDropDown from '@/components/subcomponents/AdvActionsDropDown.vue'
import OutstandingCompNotice from '@/components/subcomponents/OutstandingComplianceNotice.vue'
import AttachmentsList from '@/components/AttachmentList.vue';


export default {
    name: 'dynamicform',
    components: {
        'text-input': RegexText,
        'decimal-2x2-input': Decimal2x2Input,
        'text-area': TextArea,
        'passed-select': PassedSelect,
        'api-select': APISelect,
        'decimal-input': DecimalInput,
        'integer-input': IntegerInput,
        'gps-input': GPSInput,
        'phone-input': PhoneInput,
        'cert-input': CertInput,
        'email-input': EmailInput,
        'array-input': FormArrayInput,
        'radio-select': RadioInput,
        'bool-select': BoolInput,
        'address-input': AddressInput,
        'nested-child-forms': NestedForm,
        'event-list': EventList,
        'issue-to-fulcrum-modal': IssueFulcrum,
        'flag-list': FlagList,
        'submit-discard': SubmitDiscard,
        'section-header': SectionHeader,
        'hover-image-with-modal': Image,
        'change-parent': ChangeParent,
        'adv-opt-dd': AdvancedActionsDropDown,
        'form-image': FormImage,
        'outstanding-compliance-notice': OutstandingCompNotice,
        'attachment-list': AttachmentsList
    },
    props:{
        localFormID: {
            type: String,
            default: () =>{ return uuidv4(); }
        },
        formID: {
            type: String,
            default: () =>{ return null; }
        },
        inspectionTarget: {
            type: String,
            default: () =>{ return null; }
        },
        titleElements: {
            type: Array,
            default: () => { return []; }
        },
        showTitle: {
            type: Boolean,
            default: () => { return true; }
        },
        showBGColor: {
            type: Boolean,
            default: () => { return false; }
        },
        showFlags:{
            type: Boolean,
            default: () => { return true; }
        },
        backOnDiscard:{
            type: Boolean,
            default: () => { return true; }
        },
        runOnDiscard:{
            type: Function,
            default: () => { return ()=>{console.log("Discard Form Run")}}
        },
        hideFields: {
            type: Boolean,
            default: () => { return false; }
        },
        hideEventList: {
            type: Boolean,
            default: () => { return false; }
        },
        isChild:{
            type: Boolean,
            default: ()=>{ return false; }
        },
        showPhoto:{
            type: Boolean,
            default: ()=>{ return true; }
        },
        vertScroll:{
            type: Boolean,
            default: ()=>{ return true; }
        }
    },
    data(){
        return{
            name_pluaral: 'Test Bed',
            formKey: 0,
            id: null,
            inspect: null,
            anyDirty: false,
            hasNestedChildren: false,
            formDef:{
                meta: {
                    name: '',
                    title: '',
                    showEventHist: false,
                    formParentKey: null,
                    formInspects: null,
                },
                fields: {}
            },
            formTitle: "Loading...",
            breadcrumbs: [],
            deviceMostRecentPhoto: {
                photoType: null,
                photo: null,
                eventID: null,
                tstamp: null
            },
            changeParentIncrement: 0
        }
    },
    methods: {
        requestChangeParent(){
            console.log('Requested Change Parent')
            if(this.formDef.meta.formInspects != 'customers'){
                this.changeParentIncrement++;
            }
        },
        parseTimestampToDate(input){
            return butils.formatters.timestampToDateTime(input);
        },
        mostRecentPhotoLink(){
            if(this.deviceMostRecentPhoto.photoType == 'BFPA Test'){
                return '/home/test/' + this.deviceMostRecentPhoto.eventID;
            }else if(this.deviceMostRecentPhoto.photoType == 'Survey'){
                return '/home/survey/' + this.deviceMostRecentPhoto.eventID;
            }else if(this.deviceMostRecentPhoto.photoType == 'Installation' 
                    || this.deviceMostRecentPhoto.photoType == 'Replacement' 
                    || this.deviceMostRecentPhoto.photoType == 'Removal For Replacement'){
                return '/home/replacement/' + this.deviceMostRecentPhoto.eventID;
            }
        },
        eventActionTriggered(val){
            let parent = val.parentID;
            let eventAction = val.action;
            if(parent == this.formDef.meta.inspect){
                if(eventAction == 'addtest'){
                    this.$router.push({path: '/home/workorder/' + this.formDef.meta.inspect })
                }else if(eventAction == 'addinstall'){
                    this.$router.push({path: '/home/add/repinst/install/' + this.formDef.meta.inspect })
                }else if(eventAction == 'issuetofulcrum'){
                    this.$bvModal.show('fulcrum-issue-modal-' + this.localFormID);
                }else if(eventAction == 'addnewdevice'){
                    this.$router.push({path: '/home/create/devices/' + this.formDef.meta.inspect })
                }else if(eventAction == 'addnewconnection'){
                    this.$router.push({path: '/home/create/connections/' + this.formDef.meta.inspect })
                }else if(eventAction == 'addnewsite'){
                    this.$router.push({path: '/home/create/sites/' + this.formDef.meta.inspect })
                }else if(eventAction == 'addGenericTask'){
                    this.$router.push({path: '/home/generictaskentry/' + this.formDef.meta.inspect })
                }
            }else{ 
                console.log(`Recieved ${eventAction} Event For Parent ID: ${parent} Discarded As It Does Not Belong To: ${this.formDef.meta.inspect}`);
            }
        },
        goToParent(){
            if(this.formDef.meta.formParentKey != null){
                var parentIDFields = this.formDef.fields.filter((fld)=>{
                    return (this.formDef.meta.formParentKey == fld.dataName)
                });
                if(parentIDFields.length > 0){
                    console.log(parentIDFields[0].value);
                    this.$router.push({path: '/home/form/' + this.formDef.meta.formParentFormID + '/' + parentIDFields[0].value })
                    this.formKey++;
                }
            }
        },
        goToAddTest(){
            this.$router.push({path: '/home/add/test/' + this.formDef.meta.inspect })
        },
        fakeValidation(input){ return true; },
        fakeSubmit(){return null},
        validateForm(evt){
            evt.preventDefault()
        },
        fetchValues(evt){
            evt.preventDefault()
        },
        getFormValidationResult(){
            console.log("Button Pressed")
            var x = this.checkFormForValiditiy(this.$store.getters.getForm(this.localFormID));
            if(x.valid)
                console.log("Form Is Valid! YAY!")
            else
                console.log(x);
        },
        checkFormForValiditiy(form){
            // Remove Meta From Consideration
            var valid = _.omit(form, 'meta');
            // Get Keys
            var keys = _.keys(valid);
            // Check Each Key
            var allValidations = {};
            keys.forEach((key)=>{
                if(valid[key].type == "formarrayengine"){
                    valid[key].value.forEach((itm, index) =>{
                        // Put This Value
                        allValidations[key + '-_-' + index] = itm.valid;
                    }) 
                }else{
                    allValidations[key] = valid[key].valid;
                }
            });
            // Omit Passing Keys
            var failures = _.omit(allValidations, (val, key, obj) => {
                return !!(val);
            });
            var valid;
            if(_.keys(failures).length > 0){
                valid = false;
            }else{
                valid = true;
            }
            return {
                valid,
                failures
            };
        },
        fetchForm(apiPath){
            //Get User Token and Fetch The Values Required For This Page
            instance.get(process.env.VUE_APP_API_BASE_URL + '/' + apiPath)
                .then(async (response) => {
                    //console.log("Dynamic Form Data Fetched")
                    //console.log(response.data.result);
                    this.$store.commit('initForm',{ localFormID: this.localFormID, meta: response.data.result.meta });
                    this.formDef.meta = response.data.result.meta;
                    var fields = [];
                    // check for an address
                    // this isnt a great solution but it will work for now...
                    var addressComponents = response.data.result.fields.filter((fld)=>{return fld.dataName.match(/_address_/g) != null })
                    if(addressComponents.length > 0){ // Added False To Skip This Usage For Now                        
                        var adrsNumberField = response.data.result.fields.filter((fld)=>{return fld.dataName.match(/_address_number/g) != null })
                        
                        var adrsField = null;
                        
                        try {
                            if (adrsNumberField[0] !== undefined && adrsNumberField[0].dataName !== undefined) {
                                var dataNamePrefix = adrsNumberField[0].dataName.substring(0 ,adrsNumberField[0].dataName.length-("_number".length));
                                adrsField = adrsNumberField[0];
                                adrsField.inputLabel = (dataNamePrefix.replace(/_/g," ")).split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
                                adrsField.parent = null;
                                adrsField.value = {
                                    dataNamePrefix: dataNamePrefix,
                                    number: adrsNumberField[0].value,
                                    predirectional: addressComponents.filter((fld)=>{return fld.dataName.match(/_predirection/g) != null})[0].value || null,
                                    name: addressComponents.filter((fld)=>{return fld.dataName.match(/_street_name/g) != null})[0].value || null,
                                    suffix: addressComponents.filter((fld)=>{return fld.dataName.match(/_street_suffix/g) != null})[0].value || null,
                                    postdirectional: addressComponents.filter((fld)=>{return fld.dataName.match(/_postdirection/g) != null})[0].value || null,
                                    unit: addressComponents.filter((fld)=>{return fld.dataName.match(/_unit/g) != null})[0].value || null,
                                    building: (addressComponents.filter((fld)=>{return fld.dataName.match(/_building/g) != null}).length > 0) ? addressComponents.filter((fld)=>{return fld.dataName.match(/_building/g) != null})[0].value : null,
                                    extra: addressComponents.filter((fld)=>{return fld.dataName.match(/_extra/g) != null})[0].value || null,
                                    city: addressComponents.filter((fld)=>{return fld.dataName.match(/_city/g) != null})[0].value || null,
                                    state: addressComponents.filter((fld)=>{return fld.dataName.match(/_state/g) != null})[0].value || null,
                                    zip: addressComponents.filter((fld)=>{return fld.dataName.match(/_zip_code/g) != null})[0].value || null,
                                    zipPlusFour: addressComponents.filter((fld)=>{return fld.dataName.match(/_zip_code_plus_four/g) != null})[0].value || null,
                                    zipPlusTwo: addressComponents.filter((fld)=>{return fld.dataName.match(/_zip_code_plus_two/g) != null})[0].value || null,
                                    lineTwo: addressComponents.filter((fld)=>{return fld.dataName.match(/_line_two/g) != null})[0].value || null
                                };
                                adrsField.type = 'address-input';
                            }

                        } catch (error) {
                            console.error(error)
                        }

                        // this.formDef.fields = [adrsField];
                        // create a dictionary of used dataNames and only allow one of each
                        var usedDataNames = {};                        
                        var fieldsToUse = response.data.result.fields.filter((fld)=>{ 
                            if(usedDataNames[fld.dataName] == null){
                                usedDataNames[fld.dataName] = true;
                                return !addressComponents.includes(fld) || fld.dataName.match(/_line_/g);
                            }
                            return false;
                        })
                        if (adrsField) {
                            fieldsToUse.push(adrsField);
                        }
                        
                        fieldsToUse.sort((a,b)=>{
                            return a.ordinal_position - b.ordinal_position;
                        })
                        fields = fieldsToUse;                    
                        
                        
                    }else{
                        fields = response.data.result.fields;
                    }
                    var foundLastNestAt = -1;
                    // Find the last instance of nested children
                    // While seeking nested children, check for allowed form usage
                    for (let i = 0; i < fields.length; i++) {
                        if(fields[i].type == "nested-child-forms"){
                            this.hasNestedChildren = true;
                            foundLastNestAt = i;
                            if(this.formDef.meta.isCustomForm == true){
                                // this will narrow us down to only the custom form nested child forms fields
                                if(fields[i].dataName == 'devices_under_this_connection' || fields[i].dataName == 'connections_under_this_site' || fields[i].dataName == 'sites_under_this_customer'){
                                    let useDefault = false;
                                    let inspectsType = null;
                                    if(fields[i].formID != null){
                                        if(!this.$store.getters.hasAccessToFormID({ formID: fields[i].formID })){
                                            // We cannot fullful that request for this user, so we will use whatever the default form is for this user and the given child inspection type
                                            useDefault = true;
                                            if(!_.isArray(fields[i].options)){
                                                if(_.has(fields[i].options, 'child_is')){
                                                    inspectsType = fields[i].options.child_is;
                                                }
                                            }
                                        }
                                    }else{
                                        if(!_.isArray(fields[i].options)){
                                            if(_.has(fields[i].options, 'child_is')){
                                                inspectsType = fields[i].options.child_is;
                                                useDefault = true;
                                            }else{
                                                console.log(`ERROR - Dynamic Form - Found Nested Child Field In Custom Form That Doesn't Have A Valid Set of Options AND This User Does Not Have Access To The FormID Specified or The FormID Specified Is NULL. formID: ${fields[i].formID}`);
                                            }
                                        }else{
                                            console.log(`ERROR - Dynamic Form - Found Nested Child Field In Custom Form That Doesn't Have A Valid Set of Options AND This User Does Not Have Access To The FormID Specified or The FormID Specified Is NULL. formID: ${fields[i].formID}`);
                                            // We cannot determine easily what this form actually inspects
                                            // TODO: Perform lookup via /forms/type/:form_id.json endpoint to figure out what type this form inspects, then process from there
                                            // Until the TODO item is completed, we will just use the form specified
                                        }
                                    }

                                    if(useDefault){
                                        if(inspectsType != null){
                                            // We can now lookup the default form of this type for the current user
                                            var defaultFormForType = this.$store.getters.getAvailableFormsByInspectionType({inspecting: inspectsType});
                                            if(defaultFormForType.length > 0){
                                                // Nab the first one, it's good enough for what we want to do.
                                                // console.log(defaultFormForType[0])
                                                fields[i].formID = defaultFormForType[0].id;
                                            }else{
                                                // No Default Form Could Be Found, use specified
                                            }
                                        }
                                    }else{
                                        // Using what is specified, no action required
                                    }
                                }
                            }
                        }
                    }

                    if(this.hasNestedChildren){
                        var submitter = {
                            dataName: "submit_discard",
                            formID: fields[0].formID,
                            type: 'submit-discard'
                        };
                        // var val = "";
                        // if(this.formDef.meta.formInspects == 'sites'){
                        //     val = "Connections";
                        // }else if(this.formDef.meta.formInspects == 'customers'){
                        //     val = "Sites";
                        // }else if(this.formDef.meta.formInspects == 'connections'){
                        //     val = "Devices";
                        // }
                        // var sectionHeader = {
                        //     dataName: "children_header",
                        //     formID: fields[0].formID,
                        //     type: 'section-header',
                        //     value: val
                        // };
                        // fields.splice(foundLastNestAt, 0, sectionHeader);
                        fields.splice(foundLastNestAt, 0, submitter);
                    }
                    
                    this.formDef.fields = fields;
                    // console.log(this.formDef.fields)
                    // console.log(response.data.result.meta.title)
                    this.formTitle = response.data.result.meta.title;
                    if( this.formDef.meta.formInspects == 'devices' ){
                        this.fetchMostRecentDevicePhoto(this.formDef.meta.inspect);
                    }
                    this.sendTitleToParent();

                    if(!!this.showTitle && this.formDef.meta.formParentKey != null && !this.isChild){
                        this.fetchBreadcrumbs(this.formDef.meta.inspect);
                    }
                    
                })
                .catch((error) => {
                    if(butils.isError401(error)){
                        console.log(error.response.data);
                        butils.createToast(this, 'Logged Out', 'Login Again', 'warning')
                    }
                    console.log(error);
                });
        },
        sendForm(evt){
            evt.preventDefault();
            var form = this.$store.getters.getForm(this.localFormID);
            console.log(form);
            //Get User Token and Fetch The Values Required For This Page
            instance.put(process.env.VUE_APP_API_BASE_URL + form.meta.updateEndpoint.url, {form:form})
                .then(async (response) => {
                    console.log(response.data);
                    this.makeToast('Success', 'Form Updated Succesfully', 'success');
                    //this.$store.commit('clearForm', response.data.result.meta);
                    // this.formDef.meta = response.data.result.meta;
                    // this.formDef.fields = response.data.result.fields;
                    
                })
                .catch((error) => {
                    console.log(error);
                    this.makeToast('Failure', 'Form Save Error', 'danger');
                });
        },
        fetchBreadcrumbs(inspectingID){
            this.breadcrumbs = [];
            instance.get(process.env.VUE_APP_API_BASE_URL + '/atils/breadcrumbs/' + inspectingID + '.json')
            .then((response)=>{
                //console.log(response.data.result)
                var bc = response.data.result;
                if(bc.customers != null){
                    var active = (bc.id_is == 'customers') ? true : false;
                    var tmp = {
                        text: bc.customer_title,
                        active: active,
                        to: this.getFormLink(bc.customers, 'customers')
                    };
                    if(tmp.text == null || tmp.text.trim() == ''){
                        tmp.text = 'Customer';
                    }
                    this.breadcrumbs.push(tmp);
                }
                if(bc.sites != null){
                    var active = (bc.id_is == 'sites') ? true : false;
                    var tmp = {
                        text: bc.site_title,
                        active: active,
                        to: this.getFormLink(bc.sites, 'sites')
                    };
                    if(tmp.text == null || tmp.text.trim() == ''){
                        tmp.text = 'Site';
                    }
                    this.breadcrumbs.push(tmp);
                }
                if(bc.connections != null){
                    var active = (bc.id_is == 'connections') ? true : false;
                    var tmp = {
                        text: bc.connection_title,
                        active: active,
                        to: this.getFormLink(bc.connections, 'connections')
                    };
                    if(tmp.text == null || tmp.text.trim() == ''){
                        tmp.text = 'Connection';
                    }
                    this.breadcrumbs.push(tmp);
                }
                if(bc.devices != null){
                    var active = (bc.id_is == 'devices') ? true : false;
                    var tmp = {
                        text: bc.device_title,
                        active: active,
                        to: this.getFormLink(bc.devices, 'devices')
                    };
                    if(tmp.text == null || tmp.text.trim() == ''){
                        tmp.text = 'Device';
                    }
                    this.breadcrumbs.push(tmp);
                }
                if(bc.event != null){
                    var active = (bc.id_is == 'event') ? true : false;
                    var tmp = {
                        text: bc.event_title,
                        active: active
                    };
                    this.breadcrumbs.push(tmp);
                }
            })
            .catch((err)=>{
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning')
                }
                console.log(err);
            })
        },
        fetchMostRecentDevicePhoto(inspectingID){
            instance.get(process.env.VUE_APP_API_BASE_URL + '/atils/photo/device/recent/' + inspectingID)
            .then((response)=>{
                // console.log(response.data.result)
                var mrp = response.data.result;
                this.deviceMostRecentPhoto = {
                    photoType: mrp.photo_type,
                    photo: mrp.photo,
                    eventID: mrp.event_id,
                    tstamp: mrp.event_tstamp
                };
            })
            .catch((err)=>{
                console.log(err);
            })
        },
        getFormLink(inspectID, isType){
            var defaultFormForType = this.$store.getters.getAvailableFormsByInspectionType({inspecting: isType});
            if(defaultFormForType == null){
                return '.'
            }else if(defaultFormForType.length == 0){
                return '.'
            }else{
                return '/home/form/' + defaultFormForType[0].id + '/' + inspectID
            }
        },
        makeToast(toastTitle, toastBody = '', toastVariant = null){
            this.$bvToast.toast(toastBody, {
                variant: toastVariant,
                toaster: 'b-toaster-bottom-center',
                title: toastTitle,
                autoHideDelay: 3000,
                appendToast: true
            })
        },
        sendTitleToParent (event) {
            // console.log("Sending Form Title To Parent")
            this.$emit('sendTitleToParent', this.formTitle);
            if(!this.isChild){
                document.title = `${this.formTitle}`;
            }
        },
        initBeforeMount(){
            // this.$store.commit('clearForm', {name: "Form Recently Cleared"});
            this.formDef = {
                meta: {
                    name: '',
                    title: '',
                    showEventHist: false,
                    formParentKey: null
                },
                fields: {}
            };
            // if(this.formID == null){
            //     console.log("Prop FormID is Null")
            // }
            // if(this.inspectionTarget == null){
            //     console.log("Prop inspectionTarget is Null")
            // }
            if(this.formID == null || this.inspectionTarget == null){
                //console.log(`Form Invoked Router Value: form_id: ${this.$route.params.id} Inspecting: ${this.$route.params.inspect}`)
                this.fetchForm('forms/' + this.$route.params.id + '.json?inspect=' + this.$route.params.inspect);
                this.inspect = this.$route.params.inspect;
            }else{
                //console.log(`Form Invoked Prop Value: form_id: ${this.formID} Inspecting: ${this.inspectionTarget}`)
                this.fetchForm('forms/' + this.formID + '.json?inspect=' + this.inspectionTarget);
                this.inspect = this.inspectionTarget;
            }
        },
        initBeforeCreate(){
            // this.$store.commit('clearForm', {name: "Form Recently Cleared"});
        },
        doAllInit(){
            //this.initBeforeCreate();
            this.initBeforeMount();
        }
    },
    watch:{
        '$route': 'doAllInit'
    },
    computed: {
        formValid: function(){
            
            
            //this.$store.getters.getEntireFormValid;
        },
        childrenDataNames: function(){
            // Remove Meta From Consideration
            var valid = _.omit(this.$store.getters.getForm(this.localFormID), 'meta');
            // Get Keys
            return _.keys(valid);
        }
    },
    mounted(){
        // console.log("Dynamic Form Mounted")
        var x = this.$store.getters.getEntireFormValid;
        
    },
    beforeDestroy(){
        this.$store.commit('clearForm', {localFormID: this.localFormID});
    },
    beforeMount (){
        this.initBeforeMount();
    },
    beforeCreate(){
        //this.initBeforeCreate();
    },
}
</script>

<style lang="scss" scoped>
.nested-form-left-margin{
    margin-left: min(3rem, 3vw)
}
.breadcrumbs-bar-static{
    position: fixed;
    width: 100%;
    z-index: 10;
}
.breadcrumb-item a{
    color: $vai-bright-blue;
}
li.active span{
    color: $vai-bright-blue;
    font-weight: 600;
}
.scrollable-veritically{
    height: calc(100vh - 56px);
    width: calc(100% + 15px);
    overflow-y: auto;
    overflow-x: hidden;
}
div.form-group{
    margin-bottom: 0rem;
}
.photo-section{
    max-width: 250px;
}
.title-bar{
    display: inline-block;
}
.form-sub-title{
    font-size: 1.25rem;
}
.form-title{
    font-size: 2rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 0;
}
.array-spaced{
  margin-bottom: 0.5rem;
}
.fgroup-spacer{
    width: 100%;
    height: 1px;
    margin-bottom: 0.5rem;
}
.blue-bg{
    background-color: #ebf5ff;
    border-radius: 1em;
    border-width: 0.5em;
    padding: 0.5em;
}
.larger-header{
    font-size: 1.5em;
}
.drop-down{
    font-size: 2em;
    margin-right: 0.5em;
}
.text-match-drop-down{
    font-size: 1.25em;
}
</style>